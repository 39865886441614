<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import { mapActions } from 'vuex'
import MainPage from './components/MainPage.vue'
import About from './components/About.vue'

Vue.use(VueRouter)
const routes = [
  {
    name: 'main',
    path: '/',
    component: MainPage,
    children: [{ name: 'about', path: '/about', component: About }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes // short for `routes: routes`
})

export default {
  name: 'App',
  router,
  created () {
    this.loadData()
    this.loadCategories()
  },
  methods: {
    ...mapActions(['loadData', 'loadCategories'])
  }
}
</script>

<style lang="scss">
@import './assets/global.scss';

body {
  margin: 0px;
}

#app {
  min-height: 100vh;
  // margin-left: 100px;
  // margin-top: 60px;

  .router-link, .router-link-exact-active, .router-link-active {
    text-decoration: none;
    color: inherit;
  }

  .slide-left-enter, .slide-right-leave-active {
    opacity: 0;
    -webkit-transform: translate(30px, 0);
    transform: translate(30px, 0);
  }
  .slide-left-leave-active, .slide-right-enter {
    opacity: 0;
    -webkit-transform: translate(-30px, 0);
    transform: translate(-30px, 0);
  }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity .5s
    }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }
}
</style>

<template>
<div class="projects-container">
  <div class="inner-projects-container" @mouseleave="selectedProject = null">
    <div v-for="(category, c) in categories" :key="c" class="posts">
      <div class="individual-category">
          <h3>
            {{category.name}}
          </h3>
        </div>
        <div v-for="(element,e) in reorderedData[c]" :key="e" class="single-project-container">
          <img class="preview-image" :src="element.image" :class="{'shown': selectedProject === element.data.id}"/>
          <a :href="element.data.link" @mouseenter="selectedProject = element.data.id">
            <h1 class="single-project-title">
              {{element.data.title.rendered}}
            </h1>
          </a>
          <!-- <p class="date-and-place">2017, Milano (IT)</p> -->
        </div>
        <div class="closing-paragraph"><p class="end-label">&bull; end of list</p></div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProjectsGrid',
  data () {
    return {
      categoryKeys: [],
      reorderedData: {},
      selectedProject: null
    }
  },
  computed: {
    ...mapState(['posts', 'status', 'categories'])
  },
  mounted () {
    this.categoryKeys = Object.keys(this.categories)
    // console.log(this.posts.length, this.posts)
    this.groupedByCategory = this.categoryKeys.map((Objkey) => {
      const currentData = this.posts.map((element) => {
        if (element.data.categories.indexOf(+Objkey) === -1) {
          return []
        } else {
          return element
        }
      })
      this.reorderedData[Objkey] = currentData.flat()
      // console.log(this.reorderedData[Objkey])
      return this.reorderedData
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.projects-container {
  height: 95vh;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  .inner-projects-container {
    display: inline-flex;

    .posts {
    margin: 1% 1%;
    width: 800px;
    // border-right: 1px solid blue;

    .individual-category {
      text-align: right;
      margin: 1%;
      h3 {
        display: inline-block;
        padding: 1%;
        border: 1px solid black;
        box-shadow: -2.5px 2.5px 0px black;
      }
    }

    .closing-paragraph {
      text-align: center;
      margin-top: 5%;
      .end-label {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    }
    .single-project-container {
      // display: inline-flex;
      a {
        color: inherit;
        text-decoration: none;
      }
      // background-color: aquamarine;
      border-bottom: 1px dotted black;
      .single-project-title {
        // background-color: lemonchiffon;
        font-weight: normal;
        font-size: 25px;
        width: 60%;
        margin-bottom: 1%;
      }
      .preview-image {
        opacity: 0;
        mix-blend-mode: multiply;
        pointer-events: none;
        position: fixed;
        right: 2%;
        top: 2%;
        // z-index: 0;
        max-width: 800px;
        transition: opacity 0.2s;
      }

      & .shown {
        opacity: 1;
        transition: opacity 0.2s;
      }
    }

  }
}
</style>

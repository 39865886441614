<template>
  <div class="about-container">
    <div class="descriptions">
        <div class="description">
          <p id="eng">
            Marco Fava is a freelance with a degree in architecture at
            Politecnico di Milano in 2006. Besides having experience in
            architecture and interior design, he mainly deals with visual
            communication, from 3D visualization to graphic and editorial design.
            Alongside design works he develops photography research projects and he
            collaborates with curators and other photographers.
            Marco Fava has a strong multidisciplinary approach and
            He faces every project in a transversal way. His work
            is enhanced through the contamination of different disciplines
            and his projects were published on several online
            platforms and he took part in collective and personal exhibitions
            including “Lacuna/ae - Identity and Modern Architecture in Venice
            Venice, 2016” and “Diecixdieci Contemporary Photography Festival
            in Gonzaga (Italy), 2019”. From 2016 he collaborates as designer
            and editor on editorial projects with “Ikonemi - Indipendent
            center for photography” and other professionals. In 2019
            he published his first photobook "Il Servizio Buono", made
            in collaboration with two designers and joined Scenario in 2020,
            a consulting agency and visual platform.
          </p>
        </div>
    </div>
    <div class="features">
      <h2>Publications / features:</h2>
      <div class="publication-container">
        <div v-for="(year, y) in years" :key="y" class="single-year">
          <p class="year-title">{{year}}</p>
          <div v-for="(feature, f) in Features[year]" :key="`${f}-element`">
            <a v-if="feature.link" :href="feature.link">{{feature.title}}</a>
            <p v-else>{{feature.title}}</p>
          </div>
        </div>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
import Features from '../../public/data/features.json'

export default {
  name: 'About',
  data () {
    return {
      Features,
      years: Object.keys(Features).reverse()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.about-container {
  // background-color: lightblue;
  min-height: 65vh;

  .descriptions {
    width: 100%;
    font-size: 40px;
    // display: inline-flex;
    margin-top: 3%;
  }

  .features {
    margin-top: 5%;
    .publication-container {
      display: inline-flex;
      width: 100%;
      white-space: nowrap;
      position: relative;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      .single-year {
        min-width: 200px;
        display: block;
      }
    }
  }
}
</style>

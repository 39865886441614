<template>
  <div class="hello">
    <div class="container">
      <div class="inner-about" :class="$route.name === 'about' ? '' : 'about-inactive'">
        <div class="titles">
          <h1 class="portfolio-title">Studio Fava</h1>
          <h1 class="portfolio-subtitle">info@marco-fava.net</h1>
        </div>
        <transition :name="transitionName">
          <router-view class="child-view"></router-view>
        </transition>
      </div>
      <div class="inner-container" :class="$route.name === 'about' ? 'about-active' : ''">
        <div class="about-link">
            <h4>
              <router-link to="/about" class="router-link" v-if="$route.name === 'main'">
              <span>&#10070;</span> About
              </router-link>
              <router-link to="/" class="router-link" v-if="$route.name === 'about'">
              <span>&#10070;</span> Main
              </router-link>
            </h4>
        </div>
        <transition name="fade" mode="out-in">
        <ProjectsGrid v-show="$route.name !== 'about'"/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProjectsGrid from './ProjectsGrid.vue'

export default {
  name: 'MainPage',
  components: {
    ProjectsGrid
  },
  data () {
    return {
      transitionName: 'slide-left'
    }
  },
  computed: {
    ...mapState(['posts', 'status'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.container {
  .inner-about {
     transition: -webkit-filter -moz-filter -o-filter -ms-filter filter, 1s;
    //  margin-top: 10%;

    &.about-inactive {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    transition: -webkit-filter -moz-filter -o-filter -ms-filter filter, 1s;
    }
    margin: 1% 2%;
    .titles {
      .portfolio-title {
        font-family: "Univers LT W04_73 Black1476050";
        margin: 0;
        font-size: 80px;
      }

      .portfolio-subtitle {
        // font-family: "Univers LT W04_73 Black1476050";
        margin: 0;
        font-size: 45px;
      }
    }
  }

  .inner-container {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0%;
    transition: top 1s;
    background-color: rgba(255,255,255,0.8);

    .about-link {
      font-family: "Univers LT W04_73 Black1476050";
      border-bottom: 1px dotted black;

      h4 {
        text-transform: uppercase;
        margin: 1%;
        span {
          font-size: 20px;
        }
      }

    }
  }

  .about-active {
    position: fixed;
    top: 93.5%;
    transition: top 1s;
    // opacity: 0;
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { isUndefined } from 'lodash'
// import createPersistedState from 'vuex-persistedstate'
// import * as Cookies from 'js-cookie'

Vue.use(Vuex)

// Status variables for fetching data
const STATUS_IDLE = 'IDLE'
const STATUS_LOADING = 'LOADING'
const STATUS_LOADING_FAILED = 'LOADING_FAILED'
const STATUS_LOADING_SUCCESS = 'LOADING_SUCCESS'

export default new Vuex.Store({
  state: {
    posts: JSON.parse(localStorage.getItem('vuex.posts') || '[]'),
    categories: JSON.parse(localStorage.getItem('vuex.categories') || '[]'),
    status: STATUS_IDLE
  },
  mutations: {
    LOAD_DATA (state, { status, data }) {
      if (!isUndefined(status)) {
        state.status = status
      }
      if (!isUndefined(data)) {
        state.posts = data || JSON.parse(localStorage.getItem('vuex.posts'))
        localStorage.setItem('vuex.posts', JSON.stringify(data))
      }
    },
    LOAD_CATEGORIES (state, { status, data }) {
      if (!isUndefined(status)) {
        state.status = status
      }
      if (!isUndefined(data)) {
        state.categories = data || JSON.parse(localStorage.getItem('vuex.categories'))
        localStorage.setItem('vuex.categories', JSON.stringify(data))
      }
    }
  },
  actions: {
    loadCategories ({ state, commit }) {
      if (state.status !== STATUS_LOADING_SUCCESS) {
        commit('LOAD_CATEGORIES', { status: STATUS_LOADING })
        axios
          .get('https://marcofava.it/projects/wp-json/wp/v2/categories')
          .then(response => {
            const allCategories = {}
            response.data.forEach((d, i) => {
              allCategories[d.id] = { name: d.name }
              // return allCategories
            })
            commit('LOAD_CATEGORIES', { status: STATUS_LOADING_SUCCESS, data: allCategories })
            // console.log(allCategories)
          })
          .catch((error) => {
            console.log({ error })
            commit('LOAD_CATEGORIES', { status: STATUS_LOADING_FAILED })
          })
      }
    },
    loadData ({ state, commit }) {
      if (state.status !== STATUS_LOADING_SUCCESS) {
        commit('LOAD_DATA', { status: STATUS_LOADING })
        axios
          .get('https://marcofava.it/projects/wp-json/wp/v2/posts?per_page=100')
          .then(response => {
            const promises = []
            const completeData = []

            response.data.forEach((d, i, len) => {
              const imgLink = d._links['wp:featuredmedia'][0].href
              promises.push(axios.get(imgLink))
            })

            Promise.all(promises).then(function (results) {
              results.forEach(function (img, r) {
                // console.log(r)
                completeData.push({
                  data: response.data[r],
                  image: img.data.source_url
                })
                commit('LOAD_DATA', { status: STATUS_LOADING_SUCCESS, data: completeData })
              })
              // console.log('i am data', completeData)
            })
            // console.log(completeData)
          })
          .catch((error) => {
            console.log({ error })
            commit('LOAD_DATA', { status: STATUS_LOADING_FAILED })
          })
      }
    }
  },
  modules: {
  }
  // plugins: [
  //   createPersistedState('posts')
  // ]
})
